import { BaseService } from "../base.service";
// import { ErrorWrapper, ResponseWrapper } from "../util";
import { ErrorWrapper } from "../util";

export class DownloadService extends BaseService {
    // static get entity() {
    //     return "article_tags"
    // }

    static apiClient(options) {
        const baseURL = process.env.VUE_APP_API_URL;
        return this.initApiClient({ baseURL, ...options });
    }

    static async downloadFile(params = {}) {
        try {
            return await this.apiClient().get('download', { params }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', params.name);
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }
}